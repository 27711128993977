import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.nav`
  margin-top: 2rem;
`;

const NavContainer = styled.ul`
  padding: 0;
`;

const NavItem = styled.li`
  list-style: none;
  & a {
    display: block;
    background: ${(props) => props.theme.red};
    margin-bottom: 0.25rem;
    padding-left: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    text-align: left;
    color: #000;
    transition: 0.3s;
  }
  & a:hover {
    background: ${(props) => props.theme.orange};
    transition: 0.3s;
    text-decoration: none;
  }
  & a::selection {
    background: transparent;
  }
`;

const Contact = styled.a``;

const ContactInfo = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  padding: 1rem;
  margin-top: -1rem;
  background: #e94e1b29;
  opacity: 1;
  transition: 0.3s;
  & a {
    color: ${(props) => props.theme.orange};
  }
  & a::selection {
    color: #000;
    background: ${(props) => props.theme.orange};
  }
  ${(props) => !props.show && "padding:0 1rem;height:0;overflow-y:hidden;opacity:0;"}
`;

const LittleTitle = styled.div`
  color: ${(props) => props.theme.red};
  font-weight: 300;
  &::selection {
    color: #000;
    background: ${(props) => props.theme.red};
  }
`;

const NavItems = [
  { title: "Music", link: "/" },
  // { title: "Artists", link: "/artists" },
  { title: "Contact" },
];

export default function Nav() {
  const [showContact, setShowContact] = useState(false);

  const handleContact = () => {
    setShowContact(!showContact);
  };

  return (
    <Wrapper>
      <NavContainer>
        {NavItems.map((item, index) => (
          <NavItem key={index}>
            {item.link ? (
              <Link to={item.link}>{item.title}</Link>
            ) : (
              <Contact onClick={handleContact}>{item.title}</Contact>
            )}
          </NavItem>
        ))}
      </NavContainer>
      <ContactInfo show={showContact}>
        <LittleTitle>Enquiries & demos</LittleTitle>
        <a href="mailto:info@latexrecords.net">info@latexrecords.net</a>
      </ContactInfo>
    </Wrapper>
  );
}
