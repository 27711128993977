import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Pagination from "../components/fragments/Pagination";
import Releases from "../components/fragments/Releases";

import RightPanel from "../components/fragments/RightPanel";
import { releases as releaseData } from "../data/releases";

export default function Home() {
  const [releases, setReleases] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [releasesPerPage, setReleasesPerPage] = useState(4);

  useEffect(() => {
    setReleases(releaseData);
  }, []);

  // Get current releases
  const indexOfLastRelease = currentPage * releasesPerPage;
  const indexOfFirstRelease = indexOfLastRelease - releasesPerPage;
  const currentReleases = releases.slice(indexOfFirstRelease, indexOfLastRelease);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Latex Records - Quality dance music by selected world artists</title>
        <meta name="description" content={releaseData[0].text} />
        <link rel="canonical" href="https://latexrecords.net" />
      </Helmet>
      <RightPanel>
        <Releases releases={currentReleases} />
        <Pagination
          releasesPerPage={releasesPerPage}
          totalReleases={releases.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      </RightPanel>
    </>
  );
}
