import React from "react";

import styled from "styled-components";

const Wrapper = styled.footer`
  background: transparent;
  text-align: center;
  /* border-top: 2px solid ${(props) => props.theme.red}; */
`;

const Credits = styled.div`
  color: white;
  font-size: 0.8rem;
  padding: 2rem;
  color: ${(props) => props.theme.orange};
  margin-left: 25%;
  ::selection {
    color: #000;
    background: ${(props) => props.theme.orange};
  }
  @media screen and (max-width: 700px) {
    margin-left: 0;
  }
`;

export default function Footer() {
  return (
    <Wrapper>
      <Credits>
        All rights reserved. Latex Records & Latex Records UK © {new Date().getFullYear()}
      </Credits>
    </Wrapper>
  );
}
