import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "styled-components";

const main = {
  black: "#3B3B3B",
  orange: "#F39200",
  red: "#E94E1B",
  darkgrey: "#6A6A6A",
  lightgrey: "#F0F0F0",
};

ReactDOM.render(
  <ThemeProvider theme={main}>
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
