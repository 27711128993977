import React from "react";
import styled from "styled-components";
// import { ReactComponent as LatexLogo } from "../../images/logos/latex-records-logo.svg";
import LatexLogo from "../../images/logos/latex-records-logo.svg";
import Links from "./Links";
import Nav from "./Nav";

import { TwitterTimelineEmbed } from "react-twitter-embed";

const Wrapper = styled.section`
  width: 100%;
  min-height: 90vh;
  padding: 2rem 3rem;
  @media screen and (max-width: 700px) {
    min-height: 0;
    padding-top: 4.5rem;
  }
`;

const LatexLogoWrapper = styled.div``;
const LatexLogoImg = styled.img``;

export default function LeftPanel({ children }) {
  return (
    <Wrapper>
      {/* Logo */}
      <LatexLogoWrapper>
        <LatexLogoImg src={LatexLogo} alt="Latex Records Logo" />
      </LatexLogoWrapper>

      {/* Navigation */}
      <Nav />

      {/* Social */}

      {/* <TwitterTimelineEmbed
        sourceType="profile"
        screenName="latexrecordsuk"
        options={{ height: 800 }}
      /> */}
      {/* Page specific content */}
      {children}
      <Links />
    </Wrapper>
  );
}
