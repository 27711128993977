import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import Footer from "./components/fragments/Footer";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import LeftPanel from "./components/fragments/LeftPanel";
import Artists from "./pages/Artists";

import Background from "./images/bkg/latex-records-pattern.jpg";

const ContentWrapper = styled.main`
  background: black;
  background: url(${Background});
  background-repeat: repeat;
  background-size: 5rem;
`;

const Content = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  height: 100%;
`;

const PanelWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  background-size: cover;
  @media screen and (max-width: 700px) {
    display: block;
  }
`;

function App() {
  return (
    <BrowserRouter>
      <ContentWrapper>
        <Content>
          <PanelWrap>
            <LeftPanel />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/artists" element={<Artists />} />
            </Routes>
          </PanelWrap>
          <Footer />
        </Content>
      </ContentWrapper>
    </BrowserRouter>
  );
}

export default App;
