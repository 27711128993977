import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 1.5rem;
  & svg {
    fill: ${(props) => props.theme.orange};
    height: 1.5rem;
  }
  @media screen and (max-width: 700px) {
    & svg {
      height: 2rem;
    }
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.orange};
  font-size: 0.8rem;
  font-weight: 500;
  ::selection {
    color: #000;
    background: ${(props) => props.theme.orange};
  }
`;

const Icons = styled.div`
  display: flex;
  /* grid-template-columns: repeat(4, 1fr); */
  margin-top: 0.5rem;
  height: 1.5rem;
`;

const Icon = styled.a`
  flex: 1;
  text-align: right;
  transition: 0.3s;
  &:hover {
    & svg {
      fill: ${(props) => props.theme.red};
      transition: 0.3s;
    }
  }
`;

export default function Links() {
  return (
    <Wrapper>
      <Title>Connect & Buy</Title>
      <Icons>
        <Icon href="https://soundcloud.com/latexrecords" target="_blank">
          <svg
            id="bc9edcac-90f7-4855-b5ac-0f5a5dd7a8eb"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 177.36 87.83"
          >
            <path
              d="M83.36,418.1a.79.79,0,0,0-.71.79l-1.3,11.23,1.3,11a.78.78,0,0,0,.71.79.79.79,0,0,0,.71-.78h0l1.53-11.05-1.53-11.23C84,418.42,83.72,418.1,83.36,418.1ZM90.65,412c-.06-.48-.36-.82-.73-.82a.83.83,0,0,0-.74.82l-1.74,18.16,1.74,17.76a.82.82,0,0,0,.74.82c.37,0,.67-.33.73-.81l2-17.77Zm27.48-16.75a1.46,1.46,0,0,0-1.34,1.51l-1.39,33.42,1.39,21.59a1.35,1.35,0,1,0,2.68,0h0L121,430.14l-1.57-33.42A1.47,1.47,0,0,0,118.13,395.21Zm-14.22,11.61a1.14,1.14,0,0,0-1,1.17l-1.57,22.14,1.57,21.42a1.14,1.14,0,0,0,1,1.16,1.15,1.15,0,0,0,1-1.16l1.78-21.42L105,408A1.15,1.15,0,0,0,103.91,406.82Zm28.67,46.42a1.8,1.8,0,0,0,1.65-1.86l1.36-21.23-1.36-44.49a1.66,1.66,0,1,0-3.3,0l-1.2,44.49,1.2,21.24A1.79,1.79,0,0,0,132.58,453.24Zm29.59.1a2.46,2.46,0,0,0,2.25-2.55h0l.94-20.63-.94-51.38a2.46,2.46,0,0,0-2.25-2.55,2.43,2.43,0,0,0-2.25,2.55l-.85,51.37s.85,20.65.85,20.65A2.43,2.43,0,0,0,162.17,453.34Zm-14.91-.08a2.11,2.11,0,0,0,2-2.21h0l1.15-20.91-1.15-44.81a2,2,0,1,0-3.9,0l-1,44.81,1,20.91A2.09,2.09,0,0,0,147.26,453.26Zm-36.27-.2a1.3,1.3,0,0,0,1.19-1.33l1.68-21.6-1.68-20.55a1.2,1.2,0,1,0-2.38,0l-1.48,20.54,1.48,21.6A1.29,1.29,0,0,0,111,453.06Zm-14.11-1.18a1,1,0,0,0,.89-1l1.88-20.76-1.88-21.56a.89.89,0,1,0-1.77,0l-1.66,21.56L96,450.89A1,1,0,0,0,96.88,451.88Zm57.81-67.28a2.26,2.26,0,0,0-2.11,2.38l-.94,43.18.94,20.77a2.12,2.12,0,1,0,4.2,0h0l1.05-20.77L156.78,387A2.25,2.25,0,0,0,154.69,384.6Zm-29.36,68.68a1.63,1.63,0,0,0,1.49-1.68l1.47-21.46-1.47-41.05a1.51,1.51,0,1,0-3,0l-1.3,41.05,1.3,21.46A1.63,1.63,0,0,0,125.33,453.28Zm16.36-2h0l1.25-21.1-1.25-46a1.81,1.81,0,1,0-3.6,0l-1.11,46,1.11,21.11a1.81,1.81,0,1,0,3.6,0Zm95.21-47.62a19.52,19.52,0,0,0-8.44,1.93c-1.73-22.42-18.22-40-38.33-40a34.82,34.82,0,0,0-14,3c-1.64.73-2.08,1.48-2.1,2.93v78.95a2.9,2.9,0,0,0,2.36,2.94l60.47,0c12,0,21.82-11.14,21.82-24.88S249,403.64,236.9,403.64ZM169.61,371.4a2.6,2.6,0,0,0-2.4,2.73l-1,56,1,20.33a2.42,2.42,0,1,0,4.8,0v0l1.07-20.34-1.07-56A2.6,2.6,0,0,0,169.61,371.4Z"
              transform="translate(-81.35 -365.57)"
            />
          </svg>
        </Icon>
        <Icon href="https://www.beatport.com/label/latex-records-uk/23219" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.61 100.31">
            <g id="a495ed9b-a063-4360-bab8-6d45b83abc5b" data-name="Layer 2">
              <g id="e1c2d447-ccfa-4d3d-8f39-8805b27d4f7b" data-name="Layer 1">
                <path d="M80.61,71.16A29.07,29.07,0,1,1,30,51.46l-19.7,19.7L0,60.82l22.23-22A15.56,15.56,0,0,0,26.79,27.6V0H41.36V27.6a29.34,29.34,0,0,1-8.87,21.5l-.66.65A29.17,29.17,0,0,1,80.61,71.16m-13.19,0a15.88,15.88,0,1,0-16,15.8,15.81,15.81,0,0,0,16-15.8" />
              </g>
            </g>
          </svg>
        </Icon>
        <Icon href="https://spotify.com" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 109.48 109.48">
            <g id="a0d3a759-37f6-40c8-a52a-635805d7de05" data-name="Layer 2">
              <g id="f5e906c9-b6b8-4c74-9b0c-e1b24da99d7b" data-name="Layer 1">
                <g
                  id="aa841879-97d6-46ce-8ad1-a85809202726"
                  data-name="b15df5f2-8459-40d6-8b13-af912b3bb509"
                >
                  <g
                    id="a74e1c1b-9a52-482d-a45f-5660a2d0a7a7"
                    data-name="f80f3368-182c-46d9-b4f3-4afaee928b01"
                  >
                    <path d="M54.75,0a54.74,54.74,0,1,0,54.73,54.75h0A54.74,54.74,0,0,0,54.75,0Zm25.1,78.94a3.42,3.42,0,0,1-4.7,1.14c-12.85-7.85-29-9.63-48.08-5.28a3.41,3.41,0,0,1-1.52-6.65h0c20.85-4.77,38.74-2.72,53.17,6.1a3.41,3.41,0,0,1,1.13,4.69ZM86.55,64a4.27,4.27,0,0,1-5.87,1.41C66,56.4,43.54,53.78,26.13,59.07a4.27,4.27,0,0,1-2.48-8.17c19.88-6,44.6-3.11,61.49,7.27A4.27,4.27,0,0,1,86.55,64Zm.57-15.52C69.48,38,40.38,37.08,23.53,42.19a5.12,5.12,0,1,1-3-9.8C39.9,26.52,72,27.66,92.35,39.72a5.12,5.12,0,0,1-4.87,9l-.35-.21Z" />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </Icon>
        <Icon href="https://twitter.com/latexrecordsuk" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
            <g id="f077cfe8-2a29-40a7-8bd7-3a6ced049b9f" data-name="Layer 2">
              <g id="e5436219-0a6b-4dac-b175-88b888926736" data-name="Layer 1">
                <path d="M200,0C310.46,0,400,89.54,400,200S310.46,400,200,400,0,310.46,0,200,89.54,0,200,0Zm93.45,152.08a98.24,98.24,0,0,0,24.05-25,96.64,96.64,0,0,1-27.69,7.59A48.37,48.37,0,0,0,311,108a96.63,96.63,0,0,1-30.61,11.7,48.24,48.24,0,0,0-82.16,44,136.86,136.86,0,0,1-99.38-50.37,48.25,48.25,0,0,0,14.92,64.36,47.77,47.77,0,0,1-21.84-6c0,.2,0,.4,0,.62a48.24,48.24,0,0,0,38.69,47.26,48.09,48.09,0,0,1-21.78.83,48.29,48.29,0,0,0,45,33.49A96.74,96.74,0,0,1,94,274.5a99.21,99.21,0,0,1-11.5-.66,136.53,136.53,0,0,0,73.91,21.65c88.68,0,137.18-73.47,137.18-137.18Q293.59,155.19,293.45,152.08Z" />
              </g>
            </g>
          </svg>
        </Icon>
      </Icons>
    </Wrapper>
  );
}
