import React from "react";
import styled, { keyframes } from "styled-components";

import BeatportLogo from "../../images/logos/beatport-full.svg";
// import { ReactComponent as BeatportLogo } from "../../images/logos/beatport-full.svg";

const pulsate = keyframes`
  0% {
        box-shadow: 0px 0px 0px 0px black;
  }
  50% {
        box-shadow: 0px 0px 10px 0px black;
  }
  100% {
        box-shadow: 0px 0px 0px 0px black;
  }
`;

const ReleaseWrapper = styled.div`
  padding: 1rem 2rem;
  background: ${(props) => props.background};
  border-bottom: 3px solid ${(props) => props.highlight};
  transform: perspective(400px) rotateY(-3deg);
  margin-bottom: 2rem;
  margin-left: -2.1rem;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 0px 0px 0px black;
  &:nth-child(odd) {
    transform: perspective(400px) rotateY(3deg);
    margin-right: -2.1rem;
    margin-left: 0;
  }
  &:hover {
    /* transform: rotateY(0);
    transition: 0.5s ease-in-out; */
    /* animation: ${pulsate} 0.75s infinite; */
    /* margin-right: -1.1rem;
    margin-left: -1.1rem; */
  }
`;

const Title = styled.h2`
  font-weight: 500;
  color: ${(props) => props.highlight};
  &::selection {
    color: ${(props) => props.background};
    background: ${(props) => props.highlight};
  }
`;

const Underline = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.highlight};
  margin: 1rem 0 1.1rem 0;
`;

const Text = styled.p`
  color: ${(props) => props.text};
  &::selection {
    color: ${(props) => props.background};
    background: ${(props) => props.highlight};
  }
`;

const Soundcloud = styled.iframe`
  width: 100%;
  border: none;
  border-radius: 3px;
  & .sc-list-nostyle {
    background-color: black !important;
  }
`;

const Purchase = styled.a`
  display: block;
  color: ${(props) => props.highlight};
  margin-top: 0.6rem;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  & svg {
    fill: ${(props) => props.highlight};
    display: inline-block;
    width: 5rem;
    margin-top: -0.5rem;
    margin-left: 0.5rem;
    transition: 0.5s ease-in-out;
  }
  &:hover {
    margin-left: 1rem;
    transition: 0.5s ease-in-out;
    text-decoration: none;
    color: ${(props) => props.highlight};
  }
`;

export default function Releases({ releases }) {
  return (
    <>
      {releases.map((item, index) => (
        <ReleaseWrapper
          key={index}
          background={item.colours.background}
          highlight={item.colours.highlight}
        >
          <Title highlight={item.colours.highlight} background={item.colours.background}>
            {item.title}
          </Title>
          <Underline highlight={item.colours.highlight} />
          <Text
            text={item.colours.text}
            highlight={item.colours.highlight}
            background={item.colours.background}
          >
            {item.text}
          </Text>
          <Soundcloud
            height={item.playlist ? "320" : "180"}
            src={item.soundcloud}
            frameborder="no"
          />
          <Purchase
            highlight={item.colours.highlight}
            href={item.purchase}
            target="_blank"
            rel="noopener noreferrer"
          >
            Purchase on
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.68 114.62">
              <g id="a128efde-e8e4-4f42-a960-091c754e7456" data-name="Layer 2">
                <g id="b9900822-a1e1-42cc-a183-544a1cb238d2" data-name="Layer 1">
                  <path d="M80.61,71.16A29.07,29.07,0,1,1,30,51.46l-19.7,19.7L0,60.82l22.23-22A15.56,15.56,0,0,0,26.79,27.6V0H41.36V27.6a29.34,29.34,0,0,1-8.87,21.5l-.66.65A29.17,29.17,0,0,1,80.61,71.16m-13.19,0a15.88,15.88,0,1,0-16,15.8,15.81,15.81,0,0,0,16-15.8" />
                  <path d="M128.47,42.83q9.17,0,14.23,3.83a12.65,12.65,0,0,1,5.07,10.69,12.5,12.5,0,0,1-2.68,8.26A13.33,13.33,0,0,1,137.4,70q12.53,2.16,12.52,13.16a13.61,13.61,0,0,1-5.38,11.41q-5.39,4.14-15.36,4.14H108V42.83ZM117.14,66.52h11.57c3.13,0,5.56-.71,7.29-2.15a7.26,7.26,0,0,0,2.6-5.9A7.16,7.16,0,0,0,136,52.6c-1.73-1.4-4.16-2.11-7.29-2.11H117.14Zm0,24.41h12.12q5.43,0,8.34-2.19a7.23,7.23,0,0,0,2.91-6.11,7.44,7.44,0,0,0-2.95-6.3q-3-2.23-8.3-2.23H117.14Z" />
                  <path d="M154.19,67.28a18.07,18.07,0,0,1,7-7.34,20.32,20.32,0,0,1,10.33-2.59A22,22,0,0,1,182,59.74a17.88,17.88,0,0,1,7.1,6.78,20.49,20.49,0,0,1,2.67,10.29,23,23,0,0,1-.24,3.27H160.69v.48A12.72,12.72,0,0,0,164,89a10.73,10.73,0,0,0,8.06,3.12,11.5,11.5,0,0,0,6.7-1.88,8.94,8.94,0,0,0,3.59-5.3H191a16.53,16.53,0,0,1-6,10.21,18.76,18.76,0,0,1-12.24,4,22.82,22.82,0,0,1-11.13-2.59,18.17,18.17,0,0,1-7.34-7.3,22.24,22.24,0,0,1-2.59-10.92,23.33,23.33,0,0,1,2.51-11M183,73.78a10.46,10.46,0,0,0-3.55-7,11.36,11.36,0,0,0-7.54-2.51,11,11,0,0,0-7.3,2.59,10.13,10.13,0,0,0-3.55,6.9Z" />
                  <path d="M233.44,91.17v7.49H228.9c-2.66,0-4.58-.55-5.74-1.67a6.43,6.43,0,0,1-1.76-4.87q-4.62,7-13.48,7-6.85,0-11-3.19a10.45,10.45,0,0,1-4.14-8.77q0-6.3,4.46-9.69t13-3.39h10.28V71.63a6.57,6.57,0,0,0-2.39-5.39,10.18,10.18,0,0,0-6.62-1.95,10.84,10.84,0,0,0-6.18,1.63,6.44,6.44,0,0,0-2.91,4.35H194a12.75,12.75,0,0,1,5.43-9.53q4.79-3.39,12.52-3.39,8.22,0,12.68,3.83t4.47,11V88.3c0,1.91.9,2.87,2.71,2.87ZM220.52,80.48H209.84A10.39,10.39,0,0,0,203.69,82a5.29,5.29,0,0,0-2.15,4.58,5.11,5.11,0,0,0,2,4.23,8.8,8.8,0,0,0,5.55,1.59c3.56,0,6.34-1,8.33-2.87a10.32,10.32,0,0,0,3.07-7.65Z" />
                  <path d="M236.83,65.41h-7.1V57.83h7.1V46.42h8.7V57.83h9.81v7.58h-9.81V86.7a4.8,4.8,0,0,0,.95,3.39,4.4,4.4,0,0,0,3.27,1h6.54v7.58H248c-3.88,0-6.72-.91-8.5-2.72s-2.67-4.6-2.67-8.37Z" />
                  <path d="M290.39,59.86a17.44,17.44,0,0,1,6.86,7.26,23.73,23.73,0,0,1,2.47,11.13,23.36,23.36,0,0,1-2.47,11,17.89,17.89,0,0,1-6.86,7.34,19.2,19.2,0,0,1-10,2.59,17.38,17.38,0,0,1-7.89-1.67,13.93,13.93,0,0,1-5.35-4.55v21.7h-8.61V57.83h7.5l1,6.06a15.84,15.84,0,0,1,13.32-6.54,19.74,19.74,0,0,1,10,2.51m-2.75,28.2A14.47,14.47,0,0,0,291,78.25a14.26,14.26,0,0,0-3.31-9.81,11.86,11.86,0,0,0-17.27,0,14.1,14.1,0,0,0-3.27,9.69,14.84,14.84,0,0,0,3.27,10A10.78,10.78,0,0,0,279,91.89a10.9,10.9,0,0,0,8.65-3.83" />
                  <path d="M312.29,96.55A18,18,0,0,1,305,89.21a22.48,22.48,0,0,1-2.6-11A22.34,22.34,0,0,1,305,67.36,18.36,18.36,0,0,1,312.29,60a23.84,23.84,0,0,1,21.77,0,18.42,18.42,0,0,1,7.34,7.38A22.45,22.45,0,0,1,344,78.25a22.59,22.59,0,0,1-2.59,11,18,18,0,0,1-7.34,7.34,24.17,24.17,0,0,1-21.77,0M331.91,88a14.36,14.36,0,0,0,3.31-9.77,14.33,14.33,0,0,0-3.31-9.77,11,11,0,0,0-8.74-3.79,10.9,10.9,0,0,0-8.69,3.79,14.4,14.4,0,0,0-3.27,9.77A14.44,14.44,0,0,0,314.48,88a10.93,10.93,0,0,0,8.69,3.79A11.06,11.06,0,0,0,331.91,88" />
                  <path d="M369.51,57.83v8h-3.83q-5.34,0-7.73,3.47a15.19,15.19,0,0,0-2.4,8.82V98.66h-8.61V57.83h7.66l.95,6.14a12.92,12.92,0,0,1,4.55-4.51,14.8,14.8,0,0,1,7.5-1.63Z" />
                  <path d="M372.3,46.42h8.61V57.83h9.89v7.58h-9.89V86.7a4.8,4.8,0,0,0,1,3.39,4.4,4.4,0,0,0,3.27,1h6.54v7.58h-8.29c-3.89,0-6.7-.91-8.46-2.72s-2.63-4.6-2.63-8.37Z" />
                </g>
              </g>
            </svg>
          </Purchase>
        </ReleaseWrapper>
      ))}
    </>
  );
}
