export const releases = [
  {
    title: "Franzo Kolms - Skyline",
    text: `Skyline by Franzo Kolms is a deep progressive house EP tested relentlessly on dance floors across the world. Both tracks on this release feature deep vibes and mystic energy. Enjoy and groove along!`,
    soundcloud:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1368293293&color=%234e4ff9&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true",
    colours: {
      text: "#ffffff",
      background: "#000000",
      highlight: "#4E4FF9",
    },
    playlist: true,
    purchase: "https://www.beatport.com/release/skyline/3583600",
  },

  {
    title: "Franzo Kolms - Have Some",
    text: `Have Some is a bouncy deep house track from the London based DJ and producer Franzo Kolms. Philip Z's remix is an absolute tech-house banger that takes the track to a whole new level with a pumping bass and vocals just in the right places to make the dance floor go nuts!`,
    soundcloud:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/380750702&color=%23dd37ff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false",
    colours: {
      text: "#ffffff",
      background: "#000000",
      highlight: "#DD37FF",
    },
    playlist: true,
    purchase: "https://www.beatport.com/release/have-some/2138769",
  },

  {
    title: "Dave Winnel & Franzo Kolms – It’s All About That",
    text: `A wicked collab between Dave Winnel and Franzo Kolms! Packed with uplifting noises, fills and thrills this release is set to dominate the electro dancefloors from Sydney to London!`,
    soundcloud:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/34503884&color=23e71619&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false",
    colours: {
      text: "#ffffff",
      background: "#000000",
      highlight: "#E71619",
    },
    playlist: false,
    purchase: "https://www.beatport.com/release/its-all-about-that/857372",
  },
  {
    title: "Chimeric & Mats Gulbrandsen – 1837",
    text: `Chimeric & Mats Gulbrandsen presents an epic electric track “1837”. Style is somewhat
        unexpected here at Latex Records UK, but as soon as the track is played on the dancefloor,
        it is easy to understand that this tune doesnt have any boundaries at tall – be it intro,
        end of the night track or in the middle peak hour of a dj set, this tune will do a massive
        damage on the dancefloor! Get your copy today exclusively at Beatport!`,
    soundcloud:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/30679368&color=238efa02&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false",
    colours: {
      text: "#ffffff",
      background: "#000000",
      highlight: "#8efa02",
    },
    playlist: false,
    purchase: "https://www.beatport.com/release/1837/840764",
  },

  {
    title: "Franzo Kolms – All U Need",
    text: `Franzo Kolms delivers new progressive sounds to the record label. And what sound it is! A proper dancefloor & party track with edgy beats and beautiful sounds. However the release is not completed without a remix from Aldee, who has taken the track to a different, deeper dimension.`,
    soundcloud:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1332622&color=2300ccff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false",
    colours: {
      text: "#ffffff",
      background: "#000000",
      highlight: "#00CCFF",
    },
    playlist: true,
    purchase: "https://www.beatport.com/release/all-u-need/829249",
  },

  {
    title: "Strichkot – Get On Up",
    text: `New single from Germany’s own Strichkot. With remixes from Guille Placencia and Ruralist Kidz this release is already supported by DJ PP, Peter Gelderblom, Ferry Corsten, Natalie Parker, DI.fm and many others.`,
    soundcloud:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/20904168&color=ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false",
    colours: {
      text: "#ffffff",
      background: "#000000",
      highlight: "#E1E525",
    },
    playlist: false,
    purchase: "https://www.beatport.com/release/get-on-up/393716",
  },
  {
    title: "Franzo Kolms – Leak",
    text: `With scary sounds and pumping bass Franzo Kolms delivers new techno bomb for dark rooms and scary places. Check it out while its hot and poisonous!`,
    soundcloud:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/14067123&color=ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false",
    colours: {
      text: "#ffffff",
      background: "#000000",
      highlight: "#9EFD3B",
    },
    playlist: false,
    purchase: "https://www.beatport.com/release/leak/382781",
  },
  {
    title: "Mats Gulbrandsen – Filtech EP",
    text: `Funky tech-house styled EP from the up and coming Norwegian dance music artist Mats Gulbrandsen. Support from MC Flipside, Miss Nine, Flash Brothers, Ruralist Kidz and many others.`,
    soundcloud:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/16745046&color=ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false",
    colours: {
      text: "#ffffff",
      background: "#000000",
      highlight: "#7405FD",
    },
    playlist: false,
    purchase: "https://www.beatport.com/release/filtech-ep/368940",
  },
];
