import React, { useLayoutEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
`;
const PageNumber = styled.a`
  display: inline-block;
  color: ${(props) => (props.current ? props.theme.orange : props.theme.red)};
  background: ${(props) => (props.current ? props.theme.red : props.theme.orange)};
  margin-right: 0.5rem;
  border-radius: 50%;
  height: 2.2rem;
  width: 2.2rem;
  padding-top: 0.25rem;
  font-weight: 500;
  text-align: center;
  border: 2px solid ${(props) => props.theme.red};
  transition: 0.3s;
  &:hover {
    color: ${(props) => props.theme.orange};
    background: ${(props) => props.theme.red};
    text-decoration: none;
    transition: 0.3s;
  }
`;

export default function Pagination({ releasesPerPage, totalReleases, paginate, currentPage }) {
  useLayoutEffect(() => {
    document.getElementById("root").scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPage]);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalReleases / releasesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Wrapper>
      {pageNumbers.map((number) => (
        <PageNumber
          key={number}
          href="#"
          onClick={() => paginate(number)}
          current={number === currentPage}
        >
          {number}
        </PageNumber>
      ))}
    </Wrapper>
  );
}
