import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
  /* background: ${(props) => props.theme.darkgrey}; */
  min-height: 90vh;
  padding: 2rem 3rem;
  border-bottom: 2px solid ${(props) => props.theme.red};
  @media screen and (max-width: 700px) {
    padding: 2rem;
  }
`;

export default function RightPanel({ children }) {
  return <Wrapper>{children}</Wrapper>;
}
